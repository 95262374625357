.contact-us {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .contact-us h2 {
    text-align: center;
    font-size: 28px;
    color: #503d38;
    margin-bottom: 20px;
  }
  
  .contact-us p {
    text-align: center;
    color: #666;
    font-size: 16px;
    margin-bottom: 30px;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    font-size: 14px;
    color: #503d38;
    margin-bottom: 8px;
    display: block;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .form-group textarea {
    resize: vertical;
    height: 120px;
  }
  
  .submit-btn {
    padding: 12px 20px;
    background-color: #503d38;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .submit-btn:hover {
    background-color: #f9a826;
  }
  
  .form-status {
    margin-top: 20px;
    color: green;
    text-align: center;
    font-size: 16px;
  }
  