.loginsignup {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;
  }
  
  .loginsignup-container {
    background: #fff;
    padding: 20px 30px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: center;
  }
  
  .loginsignup-toggle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .loginsignup-toggle button {
    flex: 1;
    padding: 10px;
    border: none;
    cursor: pointer;
    background-color: #ddd;
    color: #333;
    font-weight: bold;
  }
  
  .loginsignup-toggle button.active {
    background-color: #6200ea;
    color: #fff;
  }
  
  .loginsignup-container h1 {
    margin-bottom: 20px;
  }
  
  .loginsignup-container input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .loginsignup-container button[type=\"submit\"] {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #6200ea;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }
  
  .loginsignup-container button[type=\"submit\"]:hover {
    background-color: #4e00c1;
  }     