.App {
  text-align: center;
  padding: 0 1rem; /* Add padding for better spacing on smaller screens */
}

.App-logo {
  height: 30vmin; /* Reduce logo size for smaller devices */
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vmin); /* Adjust font size for mobile screens */
  color: white;
  padding: 1rem; /* Add padding for better alignment */
  text-align: center; /* Ensure text is centered on small screens */
}

.App-link {
  color: #61dafb;
  font-size: 1rem; /* Adjust font size for readability */
  margin-top: 1rem; /* Add spacing between elements */
  word-wrap: break-word; /* Handle long text in smaller screens */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Mobile Specific Adjustments */
@media (max-width: 768px) {
  .App-logo {
    height: 20vmin; /* Further reduce logo size on smaller devices */
  }

  .App-header {
    font-size: calc(8px + 1.5vmin); /* Scale down font size */
    padding: 0.5rem; /* Adjust padding for tighter spacing */
  }

  .App-link {
    font-size: 0.9rem; /* Slightly smaller font for links */
  }
}
