.footer {
    background-color: #503d38;
    color: #fff;
    padding: 20px 0;
  }
  
  .footer-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .footer-about,
  .footer-contact,
  .footer-social {
    flex: 1;
    margin: 20px;
  }
  
  .footer h3 {
    color: #f9a826;
  }
  
  .footer p,
  .footer ul {
    line-height: 1.8;
  }
  
  .footer-contact ul {
    list-style: none;
    padding: 0;
  }
  
  .social-icons {
    display: flex;
    gap: 15px;
  }
  
  .social-icons a {
    color: #fff;
    font-size: 24px;
    transition: color 0.3s ease;
  }
  
  .social-icons a:hover {
    color: #f9a826;
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 20px;
    border-top: 1px solid #fff;
    padding-top: 10px;
  }
  
  .footer-bottom p {
    margin: 0;
  }
  