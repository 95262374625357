/* Overall container for products */
.shopcategory-products {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-between;
  }
  
  /* Styling for the entire product card container */
  .shopcategory-products .item {
    border: 1px solid #e0e0e0;
    padding: 10px;
    text-align: center;
    border-radius: 8px;
    transition: box-shadow 0.3s ease-in-out;
    background-color: #fff;
    box-sizing: border-box;
    flex: 1 1 calc(25% - 15px); /* Adjusted size to fit four cards per row */
  }
  
  .shopcategory-products .item:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .shopcategory-products .item img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  .shopcategory-products .item .product-name {
    font-size: 14px;
    color: #333;
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .shopcategory-products .item .product-price {
    font-size: 16px;
    color: #e60023;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .shopcategory-products .item .old-price {
    font-size: 12px;
    color: #999;
    text-decoration: line-through;
  }
  
  /* Container for index and sorting options */
  .shopcategory-indexSort {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    font-family: Arial, sans-serif;
  }
  
  .shopcategory-indexSort p {
    font-size: 16px;
    color: #333;
    margin: 0;
  }
  
  /* Styling for the "Sort by" element to look like a button */
  .shopcategory-sort {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #333;
    padding: 5px 15px;
    background-color: #f0f0f0; /* Light background color */
    border: 2px solid #333;
    border-radius: 25px; /* Circular border */
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .shopcategory-sort:hover {
    background-color: #e0e0e0;
    transform: scale(1.05); /* Slightly enlarge on hover */
  }
  
  .shopcategory-sort img {
    margin-left: 5px;
    width: 10px; /* Reduced size of the icon */
    height: 10px;
    filter: invert(100%); /* Ensure icon is visible on light background */
  }
  
  /* Styling for the "Explore More" button */
  .shopcategory-loadmore {
    display: block;
    width: fit-content; /* Adjust width to be just enough to fit content */
    padding: 10px 20px; /* Add padding to the button */
    margin: 20px auto 0; /* Center the button */
    background-color: #ff6600;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    border-radius: 8px;
    transition: background-color 0.3s;
    text-align: center; /* Ensure text is centered */
  }
  
  .shopcategory-loadmore:hover {
    background-color: #e05e00;
  }
  