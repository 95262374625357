/* Global Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Roboto', sans-serif;
    color: #333;
    line-height: 1.6;
    background-color: #f9f9f9;
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  /* Hero Section */
  .about-hero {
    background-color: #9b7a53;
    background-size: cover;
    background-position: center;
    color: #fff;
    padding: 100px 20px;
    text-align: center;
    position: relative;
  }
  
  .about-hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
  }
  
  .hero-text {
    position: relative;
    z-index: 2;
  }
  
  .hero-text h1 {
    font-size: 3.5rem;
    margin-bottom: 20px;
  }
  
  .hero-text p {
    font-size: 1.5rem;
    font-weight: 300;
    margin-bottom: 40px;
  }
  
  @media (max-width: 768px) {
    .hero-text h1 {
      font-size: 2.5rem;
    }
  
    .hero-text p {
      font-size: 1.2rem;
    }
  }
  
  /* About Content */
  .about-content {
    padding: 80px 20px;
    background-color: #fff;
  }
  
  .about-content h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-align: center;
    color: +#222;
  }
  
  .about-content p {
    font-size: 1.125rem;
    margin-bottom: 40px;
    color: #555;
    text-align: center;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .about-values ul {
    display: flex;
    justify-content: center;
    list-style: none;
    padding-left: 0;
    margin: 40px 0;
    gap: 20px;
  }
  
  .about-values li {
    background-color: s#4b9cc2;
    border-radius: 10px;
    padding: 20px;
    width: 200px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .about-values li strong {
    display: block;
    margin-bottom: 10px;
    font-size: 1.2rem;
    color: #333;
  }
  .about-values {
    margin: 20px 0;
  }
  
  .about-values h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 28px;
    color: #333;
  }
  
  /* Common styling for all value boxes */
  .value-box {
    padding: 20px;
    margin: 15px 0;   /* Adds space between boxes */
    border-radius: 8px;  /* Rounded corners for each box */
    font-size: 16px;
    color: white; /* Text color */
    transition: transform 0.3s ease;  /* Adds a smooth hover effect */
  }
  
  .value-box:hover {
    transform: scale(1.05); /* Hover effect: slightly increase size */
  }
  
  /* Specific colors for each section */
  .quality {
    background-color: #4CAF50; /* Green for Quality */
  }
  
  .affordability {
    background-color: #2196F3; /* Blue for Affordability */
  }
  
  .sustainability {
    background-color: #FFC107; /* Yellow for Sustainability */
  }
  
  .inclusivity {
    background-color: #FF5722; /* Orange for Inclusivity */
  }
  
  
  
  .about-team {
    margin: 60px 0;
    text-align: center;
  }
  
  .about-team p {
    font-size: 1.125rem;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    color: #666;
  }
  
  /* CTA Section */
  .about-cta {
    background-color: #9b7a53;
    color: #fff;
    padding: 60px 20px;
    text-align: center;
    margin-top: 50px;
  }
  
  .about-cta h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .about-cta p {
    font-size: 1.2rem;
    margin-bottom: 40px;
  }
  
  .shop-btn {
    background-color: #ff6b6b;
    color: #fff;
    padding: 15px 30px;
    font-size: 1.125rem;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .shop-btn:hover {
    background-color: #ff4d4d;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .about-values ul {
      flex-direction: column;
      align-items: center;
    }
  
    .about-values li {
      width: 100%;
      max-width: 300px;
    }
  
    .about-content h2 {
      font-size: 2rem;
    }
  
    .about-cta h2 {
      font-size: 2rem;
    }
  }
  
