/* Hero.css */
.hero {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 60px 100px;
    background-color: #f9f9f9;
    border-radius: 15px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
    gap: 40px;
  }
  
  .hero-left {
    flex: 1;
    font-family: 'Arial', sans-serif;
    color: #333;
    max-width: 55%;
  }
  
  .hero-left h2 {
    font-size: 3.5rem;
    font-weight: 700;
    margin-bottom: 15px;
    color: #9b7a53;
    letter-spacing: 1px;
    line-height: 1.2;
    text-transform: uppercase;
  }
  
  .hero-left .hero-hand-icon {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
  }
  
  .hero-left .hero-hand-icon p {
    font-size: 1.6rem;
    font-weight: 600;
    color: #333;
  }
  
  .hero-left .hero-hand-icon img {
    width: 45px;
    height: 45px;
    filter: brightness(0) invert(1);
    transition: transform 0.3s ease;
  }
  
  .hero-left .hero-hand-icon:hover img {
    transform: scale(1.2);
  }
  
  .hero-left p {
    font-size: 1.1rem;
    color: #555;
    line-height: 1.8;
    margin: 15px 0;
    font-weight: 400;
  }
  
  .hero-left .hero-latest-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 12px 30px;
    background-color: #9b7a53;
    color: #fff;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.3s ease;
    letter-spacing: 1px;
    box-shadow: 0px 6px 20px rgba(155, 122, 83, 0.3);
  }
  
  .hero-left .hero-latest-btn:hover {
    background-color: #7f5b2f;
    transform: translateY(-3px);
    box-shadow: 0px 12px 30px rgba(155, 122, 83, 0.5);
  }
  
  .hero-left .highlight {
    color: #9b7a53;
    font-weight: 600;
  }
  
  .hero-right {
    flex: 1;
    max-width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .hero-right img {
    width: 100%;
    height: auto;
    max-height: 450px;
    object-fit: contain;
    border-radius: 15px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.4s ease, box-shadow 0.3s ease;
  }
  
  .hero-right img:hover {
    transform: scale(1.05);
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2);
  }
  
  /* Mobile Styles */
  @media (max-width: 768px) {
    .hero {
      flex-direction: column;
      padding: 40px 20px;
    }
  
    .hero-left {
      max-width: 100%;
      text-align: center;
      margin-bottom: 30px;
    }
  
    .hero-left h2 {
      font-size: 2.5rem;
    }
  
    .hero-left p {
      font-size: 1rem;
      margin-bottom: 10px;
    }
  
    .hero-left .hero-latest-btn {
      font-size: 1rem;
      padding: 10px 20px;
    }
  
    .hero-right {
      max-width: 100%;
      margin-top: 20px;
    }
  
    .hero-right img {
      max-width: 100%;
      border-radius: 10px;
      height: 300px;
      object-fit: cover;
    }
  }
  
  /* Tablet Styles */
  @media (max-width: 1024px) {
    .hero-left h2 {
      font-size: 3rem;
    }
  
    .hero-left .hero-latest-btn {
      font-size: 1.05rem;
      padding: 10px 25px;
    }
  }